import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Suggestions from "./suggestions";

const AutoCompleteSearch = ({ query, setQuery, suggestions, id }) => {
    const { t } = useTranslation();
    const [showSuggestions, setShowSuggestions] = useState(false);

    const handleTyping = (query) => {
        setQuery(query);
        if (query.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setShowSuggestions(false);
            setQuery(event.target.value);
        }
    };

    const specificDivRef = useRef(null);

    useEffect(() => {
        const handleCloseSuggestionModal = (event) => {
            if (
                specificDivRef.current &&
                !specificDivRef.current.contains(event.target)
            ) {
                setShowSuggestions(false);
            }
        };
        document.addEventListener("click", handleCloseSuggestionModal);
        return () => {
            document.removeEventListener("click", handleCloseSuggestionModal);
        };
    }, []);

    return (
        <>
            <div className="autocomplete-container" ref={specificDivRef}>
                <input
                    id={id}
                    className="form-control form-control-lg search-bar autocomplete-input"
                    type="text"
                    value={query}
                    placeholder={t("Keyword (e.g. Alzheimer, organization...)")}
                    autoComplete="off"
                    onChange={(e) => handleTyping(e.target.value)}
                    onFocus={() => query.length > 0 && setShowSuggestions(true)}
                    onKeyDown={handleKeyDown}
                />
                <Suggestions
                    setQuery={setQuery}
                    setShowSuggestion={setShowSuggestions}
                    showSuggestion={showSuggestions}
                    suggestions={suggestions}
                    query={query}
                />
            </div>
            <div className="input-icon">
                <div className="icon-wrapper icon-wrapper--search">
                    <img className="input-search-icon" src="/static/img/search.svg" alt={t(" location")}/>
                </div>
            </div>
        </>
    );
};

AutoCompleteSearch.propTypes = {
    query: PropTypes.string.isRequired,
    setQuery: PropTypes.func.isRequired,
    suggestions: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
};

export default AutoCompleteSearch;
